.App {
  text-align: center;
  background-image: url("pexels-ricky-esquivel-1662159.jpg");
  background-position: 0vw;
  background-size: cover;
}

.App-logo {
  height: 8vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #000000be;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  /* color: rgb(60, 60, 60); */
  color: #cd9945;
}

.App-link {
  color: #61dafb;
}

.contact-tag {
  margin-top: 10vh;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
